<template>
  <div class="page_">
    <div class="navcon_" :class="`${showHeader ? 'on' : ''} ${className}`">
      <div class="navl_"></div>
      <div class="navr_">
        <ul>
          <li :class="{ on: nowIndex === 1 }">
            <router-link :to="{ path: '/MediLink' }">{{
              $t("HOME[0]") || "首页"
            }}</router-link>
          </li>
          <li :class="{ on: nowIndex === 2 }">
            <router-link :to="{ path: '/MediLink/about' }">{{
              $t("AMG[0]") || "关于中间带"
            }}</router-link>
            <div class="list_">
              <a href="javascript:;"
                ><router-link
                  :to="{ path: '/MediLink/about', query: { loc: 1 } }"
                  >{{ $t("AMG[1]") || "企业简介" }}</router-link
                ></a
              >
              <a href="javascript:;"
                ><router-link
                  :to="{ path: '/MediLink/about', query: { loc: 2 } }"
                  >{{ $t("AMG[2]") || "发展历程" }}</router-link
                ></a
              >
              <a href="javascript:;"
                ><router-link
                  :to="{ path: '/MediLink/about', query: { loc: 3 } }"
                  >{{ $t("AMG[3]") || "商业模式" }}</router-link
                ></a
              >
              <!-- <a href="javascript:;"><router-link  :to="{path:'/MediLink/about',query:{loc:4}}">联系我们</router-link></a> -->
              <a href="javascript:;"
                ><router-link
                  :to="{ path: '/MediLink/about', query: { loc: 5 } }"
                  >{{ $t("AMG[4]") || "合作伙伴" }}</router-link
                ></a
              >
              <!-- <a href="javascript:;"><router-link  :to="{path:'/MediLink/about',query:{loc:6}}">{{$t('AMG[6]')||'招贤纳士'}}</router-link></a> -->
            </div>
          </li>
          <li :class="{ on: nowIndex === 3 }">
            <router-link :to="{ path: '/MediLink/videoCenter' }">{{
              $t("AMG[7]") || "视频中心"
            }}</router-link>
          </li>
          <li :class="{ on: nowIndex === 4 }">
            <router-link :to="{ path: '/MediLink/product' }">{{
              $t("AMG[8]") || "产品与服务"
            }}</router-link>
            <div class="list_">
              <a href="javascript:;"
                ><router-link :to="{ path: '/MediLink/product' }">{{
                  $t("AMG[20]") || "第三方管理服务"
                }}</router-link></a
              >
              <a href="javascript:;"
                ><router-link :to="{ path: '/MediLink/memberService' }">{{
                  $t("AMG[10]") || "会员服务"
                }}</router-link></a
              >
              <!-- <a href="javascript:;"><router-link :to="{path:'/MediLink/incrementService'}">增值服务</router-link></a> -->
              <!-- <a href="javascript:;"><router-link :to="{path:'/MediLink/rescueService'}">紧急医疗援助</router-link></a> -->
              <a href="javascript:;"
                ><router-link :to="{ path: '/MediLink/health' }">{{
                  $t("AMG[11]") || "健康服务"
                }}</router-link></a
              >
              <a href="javascript:;"
                ><router-link :to="{ path: '/MediLink/hospital/mainland' }">{{
                  $t("AMG[12]") || "查看医疗网络"
                }}</router-link></a
              >
            </div>
          </li>
          <li :class="{ on: nowIndex === 5 }">
            <router-link :to="{ path: '/MediLink/newscenter' }">{{
              $t("IC[0]") || "信息中心"
            }}</router-link>
            <div class="list_">
              <a href="javascript:;"
                ><router-link
                  :to="{ path: '/MediLink/newscenter', query: { loc: 1 } }"
                  >{{ $t("IC[1]") || "最新动态" }}</router-link
                ></a
              >
              <a href="javascript:;"
                ><router-link
                  :to="{ path: '/MediLink/newscenter', query: { loc: 3 } }"
                  >{{ $t("IC[3]") || "常见问题" }}</router-link
                ></a
              >
              <a href="javascript:;"
                ><router-link
                  :to="{ path: '/MediLink/newscenter', query: { loc: 4 } }"
                  >{{ $t("IC[4]") || "文件下载" }}</router-link
                ></a
              >
            </div>
          </li>
          <!-- <li :class="{ on : nowIndex===6}"><router-link :to="{path:'/MediLink/about'}">联系我们</router-link></li> -->
          <li class="noafter_" style="padding:0 3px;">|</li>
          <li>
            <span
              >{{ $i18n.locale == "zh-cn" ? "中文" : "English" }}
              <span class="icon_"></span
            ></span>
            <div class="list_">
              <a @click="changeLang('zh-cn')">中文 CN</a>
              <a @click="changeLang('en-us')">英文 EN</a>
            </div>
          </li>
          <li>
            <span
              >{{ $t("LOGIN[0]") || "登录" }} <span class="icon_"></span
            ></span>
            <div class="list_">
              <a :href="memberLogin" target="_blank">
                {{ $t("LOGIN[1]") || "会员登录" }}
                <!-- <div class="img_">
                  <img src="../../../assets/medilink/WeChat.jpg" alt="">
                  <span>中间带在线</span>
                </div> -->
              </a>
              <router-link :to="{ name: 'dashboard' }">{{
                $t("LOGIN[2]") || "医疗机构登录"
              }}</router-link>
              <a href="https://secure.medilink-global.com.cn/eccs/">{{
                $t("LOGIN[3]") || "合作伙伴登录"
              }}</a>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { getLang, setLang } from "@/utils/authorized";
export default {
  props: {
    showHeader: {
      type: Boolean
    },
    nowIndex: {
      type: Number
    },
    className: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      cTop: "",
      memberLogin: "https://provider.medilink-global.com.cn/pcadmin/loginPage"
    };
  },
  mounted() {},
  methods: {
    changeLang(lang) {
      this.$i18n.locale = lang;
      setLang(lang);
    },
    selectClick(index, name, code) {
      this.$emit("selectClick", index, name, code);
    },
    closefix() {
      this.$emit("closeBg");
    }
  }
};
</script>
<style lang="scss" scoped>
.navcon_ {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  line-height: 50px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  background-color: transparent;
  z-index: 99;
  &.bgf_ {
    background: #fff;
    color: #000000;
    a {
      color: #000000 !important;
    }
  }
  &.on {
    position: fixed;
    background-color: rgba(255, 255, 255, 0.85);
    backdrop-filter: blur(10px);
    color: #000;
    .navl_ {
      background: url(../../../assets/medilink/logo.png) center center no-repeat;
      background-size: 79px 23px;
    }
    .navr_ li a,
    .navr_ li {
      color: #000;
    }
    .navr_ li:hover::after {
      background-color: #000;
    }
    .navr_ ul li .icon_ {
      background: url(../../../assets/medilink/downiconon.png) center center
        no-repeat;
      background-size: 10px 6.6px;
    }
    .navr_ ul li::after {
      background-color: #000;
    }
  }
  .navl_ {
    width: 79px;
    height: 23px;
    left: 27px;
    position: relative;
    background: url(../../../assets/medilink/logo.png) center center no-repeat;
    background-size: 79px 23px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: top;
    }
  }
  .navr_ {
    right: 40px;
    ul,
    li {
      list-style: none;
      margin: 0;
    }
    ul {
      display: flex;
      li {
        color: #fff;
        font-size: 14px;
        height: 50px;
        line-height: 50px;
        padding: 0 33px;
        position: relative;
        cursor: pointer;
        .list_ {
          display: none;
          position: absolute;
          width: 100%;
          left: 0;
          right: 0;
          top: 50px;
          padding: 10px 0;
          background-color: #fff;
          color: #000;
          box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
          a {
            height: 30px;
            line-height: 30px;
            text-align: center;
            display: block;
            color: #000;
            position: relative;

            .img_ {
              display: none;
              position: absolute;
              background-color: #fff;
              left: -107%;
              width: 120px;
              top: 0px;
              box-shadow: 0px 6px 10px 0px rgba(0, 0, 0, 0.1);
              height: 145px;
              text-align: center;
              &::before {
                display: none;
                position: absolute;
                display: inline-block;
                top: 5px;
                left: 112px;
                width: 0;
                height: 0px;
                content: "";
                border-style: solid;
                border-width: 7px;
                border-color: #fff #fff transparent transparent;
                transform: rotate(45deg);
                box-shadow: 1px -1px 1px rgba(0, 0, 0, 0.1);
              }
              img {
                width: 120px;
                height: 120px;
              }
              span {
                padding: 0 0 5px;
                top: -9px;
                display: inline-block;
                position: relative;
              }
            }
            &:hover {
              font-weight: bold;
              .img_ {
                display: block;
              }
            }
          }
        }
        & > span {
          display: flex;
          align-items: center;
        }
        .icon_ {
          width: 10px;
          height: 6.6px;
          background: url(../../../assets/medilink/downicon.png) center center
            no-repeat;
          background-size: 10px 6.6px;
          display: inline-block;
          margin-left: 5px;
        }
        a {
          color: #fff;
        }
        &::after {
          content: "";
          position: absolute;
          display: none;
          left: 0;
          right: 0;
          bottom: 0;
          margin: 0 auto;
          background-color: #fff;
          width: 23px;
          height: 2px;
        }
        &.noafter_ ::after {
          display: none !important;
        }
        &:hover {
          .list_ {
            display: block;
          }
          &::after {
            display: block;
          }
        }
        &.on::after {
          display: block;
        }
      }
    }
  }
}
</style>
