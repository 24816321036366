<template>
  <div class="content_">
    <div class="item item4">
      <div class="hide_">
        <div>
          <router-link :to="{ path: '/MediLink/hospital/mainland' }">{{
            $t("HOME[32]") || "查看医疗网络"
          }}</router-link>
        </div>
      </div>
    </div>
    <div class="item item1">
      <div class="hide_">
        <div>{{ $t("LOGIN[5]") || "服务热线：" }}400 880 8820</div>
      </div>
    </div>
    <div class="item item2">
      <div class="hide_">
        <div>
          <div class="flex_">
            <img src="../../../assets/medilink/WeChat.jpg" alt="" />
            <span>{{ $t("HOME[26]") || "中间带在线" }}</span>
          </div>
          <div class="flex_">
            <img src="../../../assets/medilink/erweima24.jpeg" alt="" />
            <span>{{ $t("HOME[28]") || "中间带24小时" }}</span>
          </div>
          <div class="flex_">
            <img src="../../../assets/medilink/ma3.jpg" alt="" />
            <span>MediLink Global<br />中间带</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item item3" @click="scroll()"></div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    scroll() {
      document.body.scrollIntoView();
    }
  }
};
</script>
<style lang="scss" scoped>
.content_ {
  position: fixed;
  right: 0;
  bottom: 25vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 9;
  .item {
    position: relative;
    width: 0px;
    padding: 22px;
    border-radius: 6px 0px 0px 6px;
    margin-bottom: 10px;
    &.item1 {
      background: url(../../../assets/medilink/side1.png) 7px 6px no-repeat
        #00a63c;
      background-size: 30px 31px;
      .hide_ > div {
        color: #00a63c;
        font-weight: bold;
        font-size: 16px;
        height: 54px;
        line-height: 54px;
        width: 248px;
        text-align: center;
        bottom: 0;
        margin: auto 0;
      }
    }
    &.item4 {
      background: url(../../../assets/medilink/side4.png) 7px 6px no-repeat
        #00a63c;
      background-size: 30px 31px;
      .hide_ > div {
        color: #00a63c;
        font-weight: bold;
        font-size: 16px;
        height: 54px;
        line-height: 54px;
        width: 248px;
        text-align: center;
        bottom: 0;
        margin: auto 0;
      }
    }
    &.item2 {
      background: url(../../../assets/medilink/side2.png) 8px 7px no-repeat
        #00a63c;
      background-size: 28px 29px;
      .hide_ > div {
        padding: 15px 18px 13px;
      }
    }
    &.item3 {
      background: url(../../../assets/medilink/side3.png) 9px 14px no-repeat
        rgba(0, 0, 0, 0.2);
      background-size: 26px 15px;
      &:hover {
        background: url(../../../assets/medilink/side3.png) 9px 14px no-repeat
          rgba(0, 0, 0, 0.4);
        background-size: 26px 15px;
      }
    }
    &:hover {
      padding-right: 31px;
      .hide_ {
        display: block;
      }
    }
    .hide_ {
      display: none;
      position: absolute;
      right: 50px;
      padding-right: 13px;
      top: 0;
      > div {
        display: flex;
        background-color: #fff;
        justify-content: center;
        align-items: flex-start;
        box-shadow: 0px 2px 7px 0px rgba(0, 0, 0, 0.1);
      }
      .flex_ {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 12px;
        color: #000;
        img {
          width: 92px;
          height: 92px;
        }
        span {
          text-align: center;
        }
      }
    }
  }
}
</style>
